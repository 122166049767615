import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SolicitorNav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <div className='container-fluid'>
        <Link className='navbar-brand' to='/solicitorPage/AllApplications'>
          Manage applications
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          onClick={toggle}
          aria-controls='navbarSupportedContent'
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div
          className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`}
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav mb-2 mb-lg-0 ms-auto'>
            <li className='nav-item'>
              <Link
                className='nav-link border-0'
                aria-current='page'
                to='/solicitorPage/AllApplications'
                onClick={toggle}
              >
                All applications
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link border-0'
                to='/solicitorPage/NewApplication'
                onClick={toggle}
              >
                New Application
              </Link>
            </li>
            <li className='nav-item bg-danger ms-3 border-0'>
              <Link className='nav-link' to='/' onClick={toggle}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SolicitorNav;
