import React, { useState } from 'react';
import { Collapse, Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { probateFAQs } from './FAQ_files/faqs';

export const FaqPage = () => {
  const [activeTab, setActiveTab] = useState(null);

  const toggleTab = (tab) => {
    if (activeTab === tab) {
      setActiveTab(null);
    } else {
      setActiveTab(tab);
    }
  };
  return (
    <Card className='border-0'>
      <CardHeader className='bg-light border-0'>
        <CardTitle className='title text-center text-black roboto-regular border-0'>
          FAQ
        </CardTitle>
      </CardHeader>
      {probateFAQs.map((faq, index) => (
        <Card key={index} className='border-0'>
          <CardHeader
            as='link'
            className='bg-white border-0 rounded-0 text-info-emphasis  roboto-medium '
            style={{ cursor: 'pointer' }}
            onClick={() => toggleTab(index)}
          >
            {faq.title}
          </CardHeader>
          <Collapse isOpen={activeTab === index}>
            <CardBody className=' bg-light text-dark text-center roboto-regular'>
              {faq.message}
            </CardBody>
          </Collapse>
        </Card>
      ))}
    </Card>
  );
};
