import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { NavMenu } from './components/NavMenu';

import FooterPage from './components/Pages/FooterPage';
import FloatingButton from './components/ApplyComponentVer3/FloatingButton';

import HowMuch from './components/HowMuchCanIBorrow/HowMuch';
import AliBanner from './components/AliBanner/AliBanner';

const App = () => {
  const location = useLocation();
  const isSolicitorPage = location.pathname.startsWith('/solicitorPage');

  return (
    <Layout>
      {!isSolicitorPage && <NavMenu />}
      {!isSolicitorPage && <AliBanner />}

      <Routes>
        {AppRoutes.map((route, index) => {
          const { element, ...rest } = route;
          return <Route key={index} {...rest} element={element} />;
        })}
      </Routes>
      {!isSolicitorPage && <FooterPage />}
      {!isSolicitorPage && <FloatingButton />}
    </Layout>
  );
};

export default App;
