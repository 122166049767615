export const probateFAQs = [
  {
    title: 'Can you get a loan for probate?',
    message:
      'Yes, you can get a loan for probate. Probate loans, like an inheritance advance or executor loan, are designed to help beneficiaries and executors manage financial obligations during probate. They can be used for Inheritance Tax, funeral costs, property maintenance, or any other expenditures.',
  },
  {
    title: 'Can I borrow money against inherited property?',
    message:
      'Yes, you can borrow money against inherited property with a probate loan.',
  },
  {
    title: 'How long does it take to get an inheritance loan?',
    message:
      'The timeline to get an inheritance loan depends on the lender and the complexity of the estate, but it can be as quick as 48 hours from application to receiving funds.',
  },
  {
    title: 'What is a probate grant?',
    message:
      "A Grant of Probate is the legal document that gives the executor the authority to manage the deceased's estate. If an executor isn't available, a Grant of Letters of Administration is issued to a Personal Representative instead.",
  },
  {
    title: 'Do banks help with probate?',
    message:
      "Yes, some banks offer probate services. They can help with tasks like gathering information about the deceased's assets and liabilities, preparing tax forms, and distributing the estate, but their services can come with fees.",
  },
  {
    title: "What is an executor's loan?",
    message:
      'An executor loan is a type of probate loan that helps you pay the estate’s expenses such as inheritance tax, funeral costs, and property maintenance. It’s repaid when the money from the estate is released.',
  },
  {
    title: 'Can I get an advance on my inheritance?',
    message:
      'Yes, you can get an advance on your inheritance through an inheritance advance loan. It allows you to access a portion of your inheritance early and you pay it back when you get your inheritance from the estate.',
  },
  {
    title: 'How do I borrow against an inheritance?',
    message:
      'To borrow against an inheritance, you can apply for an inheritance advance or a similar type of probate loan. A lender assesses the value of the estate and your expected inheritance, and provides a loan based on that amount.',
  },
  {
    title: 'Can executors access bank accounts before probate?',
    message:
      "Generally, executors cannot access the deceased's bank accounts until they’ve obtained Grant of Probate. Some banks may release funds for specific expenses like funeral costs before the probate process is complete.",
  },
  {
    title: 'Can an executor use their own bank account?',
    message:
      "An executor should not use their personal bank account for the estate's financial transactions. Mixing personal and estate finances can cause confusion and potentially legal issues.",
  },
  {
    title: 'What banks do inheritance loans?',
    message:
      'Several financial institutions offer probate or inheritance loans, but not many traditional banks do. A popular option is to use a lender that specializes in probate loans.',
  },
  {
    title: 'Probate loan interest rates',
    message:
      'Probate loan interest rates can vary widely depending on your lender and the specific circumstances of the estate. Getting a quote or speaking to an advisor is the best way to find out what the interest rate would be for your specific loan.',
  },
  {
    title: 'What is a probate bridging loan?',
    message:
      'A probate bridging loan is similar to a normal probate loan, but they’re secured against your home. It’s an extra risk to you as your home is listed as collateral, but you can get cheaper interest rates to compensate. If you’re unsure which is best for you, our advisors can talk you through your options.',
  },
];
