import React, { useState } from 'react';
import { Collapse, Card, CardHeader } from 'reactstrap';

import HowCanItHelp from './BasePage/HowCanItHelp';
import WhoAreProbateLoansFor from './BasePage/WhoAreProbateLoansFor';
import WhatIsProbate from './BasePage/WhatIsProbate';
import WhyMightYouNeedIt from './BasePage/WhyMightYouNeedIt';

const UnderstandingProbate = () => {
  const [open, setOpen] = useState('collapseOne'); // null means no collapse is open

  const toggle = (collapseId) => {
    setOpen(open === collapseId ? null : collapseId); // Toggle logic for opening one at a time
  };

  return (
    <Card className='border-0 bg-white'>
      <div className='container-fluid'>
        <CardHeader className='row bg-white border-0'>
          <button
            className='btn btn-lg btn-light col-lg-4 border-0'
            onClick={() => toggle('collapseOne')}
            style={{ marginBottom: '1rem' }}
            disabled={open === 'collapseOne'}
          >
            <i className='title '>Probate Overview</i>
          </button>
          <button
            className='btn btn-lg btn-light border-0 col-lg-4'
            onClick={() => toggle('collapseTwo')}
            style={{ marginBottom: '1rem' }}
            disabled={open === 'collapseTwo'}
          >
            <i className='title'> Why Probate Advancements?</i>
          </button>
          <button
            className='btn btn-lg btn-light border-0 col-lg-4'
            onClick={() => toggle('collapseThree')}
            style={{ marginBottom: '1rem' }}
            disabled={open === 'collapseThree'}
          >
            <i className='title'>
              Probate Advancements: <br /> Who Qualifies?
            </i>
          </button>
        </CardHeader>
      </div>
      <div className='container-fluid'>
        <Collapse isOpen={open === 'collapseOne'}>
          <Card className='bg-white text text-primary-emphasis border-0'>
            <WhatIsProbate />
          </Card>
        </Collapse>
        <Collapse isOpen={open === 'collapseTwo'}>
          <Card className='bg-white text text-primary-emphasis border-0'>
            {/* <WhyMightYouNeedIt /> */}
            <HowCanItHelp />
          </Card>
        </Collapse>
        <Collapse isOpen={open === 'collapseThree'}>
          <Card className='bg-white text text-primary-emphasis border-0'>
            <WhoAreProbateLoansFor />
          </Card>
        </Collapse>
      </div>
    </Card>
  );
};

export default UnderstandingProbate;
