import React, { useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardSubtitle,
  CardGroup,
} from 'reactstrap';
import FilesDropZone from './FilesDropZone';

const FormDataDisplay = ({ data, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState(data);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      deceasedInfo: {
        ...formData.deceasedInfo,
        [name]: value,
      },
    });
  };

  const handleBeneficiaryChange = (index, e) => {
    const { name, value } = e.target;
    const newBeneficiaries = [...formData.beneficiaries];
    newBeneficiaries[index][name] = value;
    setFormData({ ...formData, beneficiaries: newBeneficiaries });
  };

  const handleEstateChange = (index, e) => {
    const { name, value } = e.target;
    const newEstates = [...formData.estates];
    newEstates[index][name] = value;
    setFormData({ ...formData, estates: newEstates });
  };

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    onSave(formData);
    toggleEdit();
  };

  return (
    <Container className='mb-4' style={{ padding: '10px' }}>
      <Form>
        <Card className='p-2'>
          <CardHeader>Deceased details:</CardHeader>
          <CardBody className='bg-dark'>
            <Row className='mb-1'>
              <Col md={6} className='mb-1'>
                <FormGroup>
                  <Label for='firstName' className='mb-1'>
                    Deceased First Name
                  </Label>
                  <Input
                    type='text'
                    name='firstName'
                    id='firstName'
                    value={formData.deceasedInfo.firstName}
                    onChange={handleChange}
                    readOnly={!isEditing}
                    style={{ padding: '5px', fontSize: '14px' }}
                  />
                </FormGroup>
              </Col>
              <Col md={6} className='mb-1'>
                <FormGroup>
                  <Label for='lastName' className='mb-1'>
                    Deceased Last Name
                  </Label>
                  <Input
                    type='text'
                    name='lastName'
                    id='lastName'
                    value={formData.deceasedInfo.lastName}
                    onChange={handleChange}
                    readOnly={!isEditing}
                    style={{ padding: '5px', fontSize: '14px' }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className='p-2 my-2'>
          <CardHeader>Beneficiaries:</CardHeader>
          {formData.beneficiaries.map((beneficiary, index) => (
            <CardBody key={index} className='bg-dark m-1'>
              <Row className='mb-1'>
                {/* Title */}
                <Col md={1} xs={12} className='mb-1'>
                  <FormGroup>
                    <Label for={`beneficiaryTitle${index}`} className='mb-1'>
                      Title
                    </Label>
                    <Input
                      type='text'
                      name='title'
                      id={`beneficiaryTitle${index}`}
                      value={beneficiary.title}
                      onChange={(e) => handleBeneficiaryChange(index, e)}
                      readOnly={!isEditing}
                      style={{ padding: '5px', fontSize: '14px' }}
                    />
                  </FormGroup>
                </Col>

                {/* First Name */}
                <Col md={4} xs={12} className='mb-1'>
                  <FormGroup>
                    <Label
                      for={`beneficiaryFirstName${index}`}
                      className='mb-1'
                    >
                      First Name
                    </Label>
                    <Input
                      type='text'
                      name='firstName'
                      id={`beneficiaryFirstName${index}`}
                      value={beneficiary.firstName}
                      onChange={(e) => handleBeneficiaryChange(index, e)}
                      readOnly={!isEditing}
                      style={{ padding: '5px', fontSize: '14px' }}
                    />
                  </FormGroup>
                </Col>

                {/* Last Name */}
                <Col md={4} xs={12} className='mb-1'>
                  <FormGroup>
                    <Label for={`beneficiaryLastName${index}`} className='mb-1'>
                      Last Name
                    </Label>
                    <Input
                      type='text'
                      name='lastName'
                      id={`beneficiaryLastName${index}`}
                      value={beneficiary.lastName}
                      onChange={(e) => handleBeneficiaryChange(index, e)}
                      readOnly={!isEditing}
                      style={{ padding: '5px', fontSize: '14px' }}
                    />
                  </FormGroup>
                </Col>

                {/* PPS Number */}
                <Col md={3} xs={12} className='mb-1'>
                  <FormGroup>
                    <Label for={`beneficiaryPPS${index}`} className='mb-1'>
                      PPS Number
                    </Label>
                    <Input
                      type='text'
                      name='pps'
                      id={`beneficiaryPPS${index}`}
                      value={beneficiary.pps}
                      onChange={(e) => handleBeneficiaryChange(index, e)}
                      readOnly={!isEditing}
                      style={{ padding: '5px', fontSize: '14px' }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          ))}
        </Card>
        <Card className='p-2 my-2'>
          <CardHeader>Estates</CardHeader>
          {formData.estates.map((estate, index) => (
            <CardBody key={index} className='bg-dark m-1'>
              <Row className='mb-1'>
                <Col md={8} className='mb-1'>
                  <FormGroup>
                    <Label for={`estateDescription${index}`} className='mb-1'>
                      Description
                    </Label>
                    <Input
                      type='text'
                      name='description'
                      id={`estateDescription${index}`}
                      value={estate.description}
                      onChange={(e) => handleEstateChange(index, e)}
                      readOnly={!isEditing}
                      style={{ padding: '5px', fontSize: '14px' }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4} className='mb-1'>
                  <FormGroup>
                    <Label for={`estateValue${index}`} className='mb-1'>
                      Value
                    </Label>
                    <Input
                      type='number'
                      name='value'
                      id={`estateValue${index}`}
                      value={estate.value}
                      onChange={(e) => handleEstateChange(index, e)}
                      readOnly={!isEditing}
                      style={{ padding: '5px', fontSize: '14px' }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          ))}
        </Card>
        <Card className='p-2 my-2'>
          <FormGroup className='mb-1'>
            <CardHeader>Dispute</CardHeader>
            <CardBody>
              <Input
                type='textarea'
                name='dispute'
                id='dispute'
                value={formData.dispute}
                onChange={handleChange}
                readOnly={!isEditing}
                style={{ padding: '5px', fontSize: '14px', height: '200px' }}
              />
            </CardBody>
          </FormGroup>
        </Card>

        <Button
          color={isEditing ? 'success' : 'primary'}
          onClick={isEditing ? handleSave : toggleEdit}
          style={{ fontSize: '14px', padding: '5px 10px' }}
        >
          {isEditing ? 'Save' : 'Edit'}
        </Button>
      </Form>
      {/* Files drop zone area */}

      <FilesDropZone
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        applicationId={data.id}
      />
    </Container>
  );
};

export default FormDataDisplay;
