import { useState } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import BPLoans from './ProductsPage/BPLoans';
import PLoansFB from './ProductsPage/PLoansFB';

const Products = () => {
  const [activeTab, setActiveTab] = useState('1');

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Card className=' bg-light'>
        <CardHeader className='bg-primary'>
          <Nav tabs style={{ lineHeight: '1.5' }}>
            <NavItem style={{ width: '50%', cursor: 'pointer' }}>
              <NavLink
                className={` ${activeTab === '1' ? 'active' : ''}`}
                onClick={() => toggleTab('1')}
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CardTitle
                  className={`roboto-medium tabs-title text-center ${
                    activeTab === '1' ? 'text-dark' : 'text-white'
                  }`}
                >
                  Probate advancement for beneficiaries
                </CardTitle>
              </NavLink>
            </NavItem>
            <NavItem style={{ width: '50%', cursor: 'pointer' }}>
              <NavLink
                className={` ${activeTab === '2' ? 'active' : ''}`}
                onClick={() => toggleTab('2')}
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'lightblue',
                }}
              >
                <CardTitle
                  className={`roboto-medium tabs-title text-center ${
                    activeTab === '2' ? 'text-dark' : 'text-white'
                  }`}
                >
                  Bridging Probate Advancement
                </CardTitle>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId='1'>
              <PLoansFB />
            </TabPane>
            <TabPane tabId='2'>
              <BPLoans />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

export default Products;
