import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardHeader, CardTitle, Form } from 'reactstrap';
import { formatNumberWithDots } from '../Functions/helpers';

const HowMuch = () => {
  const [totalEstateValue, setTotalEstateValue] = useState('');
  const [maxLoan, setMaxLoan] = useState('');
  useEffect(() => {
    console.log(totalEstateValue);
  }, [totalEstateValue]);

  const calculateMaxLoan = (e) => {
    e.preventDefault();
    setMaxLoan(totalEstateValue * 0.6);
  };

  return (
    <div>
      <Card className=' border-0 '>
        <CardHeader className=' bg-white border-0 text-center'>
          <CardTitle className='title roboto-bold-italic text-black text-center text-decoration-underline'>
            How much can I release early?
          </CardTitle>
          <CardBody className=' bg-white'>
            <div className=''>
              <Form onSubmit={calculateMaxLoan}>
                <div className='row'>
                  <div className='mb-3 mx-auto col-md-7'>
                    <div className='input-group'>
                      <input
                        type='text'
                        className='form-control border-dark-subtle text-center'
                        id='estateValue'
                        placeholder='Total Value Of The Estate'
                        value={totalEstateValue}
                        onChange={(e) => setTotalEstateValue(e.target.value)}
                      />
                      <button className='btn btn-secondary' type='submit'>
                        Calculate
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
            {maxLoan !== '' && maxLoan !== 0 ? (
              <div>
                <Alert className=' text-center bg-white text-dark-emphasis mt-md-0 mt-1'>
                  <h5>The maximum amount of money that can be advanced </h5>
                  <h1 className=' text-warning'>
                    €{formatNumberWithDots(maxLoan)}
                  </h1>
                </Alert>

                {/* <Alert className='bg-info'>Probably some info here</Alert> */}
              </div>
            ) : (
              <div></div>
            )}
          </CardBody>
        </CardHeader>
      </Card>
    </div>
  );
};

export default HowMuch;
