import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import CustomCard from '../Generic/CustomCard';
import CustomAlert from '../Generic/CustomAlert';

const DutiesAndResponsibilities = () => {
  return (
    <Card className='whatIsProbate bg-white border-0'>
      <CardHeader className=' bg-light border-0 text-center'>
        <CardTitle className='title  text-black roboto-medium'>
          Essential Actions for Executors of Estates
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className=' d-flex bg-white flex-column align-items-center justify-content-evenly'>
          <CardSubtitle className='title text-primary-emphasis m-lg-3'>
            If you’re named as the executor of an estate, you’ll need to:
          </CardSubtitle>

          <div className=' d-flex align-items-center justify-content-evenly flex-wrap'>
            <CustomCard backgroundColor='warning' shape='circle' width='300px'>
              Arrange and pay for the funeral
            </CustomCard>
            <CustomCard backgroundColor='warning' shape='circle' width='300px'>
              Settle any of the deceased's debts
            </CustomCard>
            <CustomCard backgroundColor='warning' shape='circle' width='300px'>
              Pay any inheritance tax due
            </CustomCard>
            <CustomCard backgroundColor='warning' shape='circle' width='300px'>
              Get a valuation of the estate (properties, investments, and any
              other possessions)
            </CustomCard>
            <CustomCard backgroundColor='warning' shape='circle' width='300px'>
              Distribute the remaining estate to the beneficiaries
            </CustomCard>
          </div>

          <p className='text text-dark-emphasis'>
            This isn't an exhaustive list, but it's some of the main
            responsibilities.
          </p>
          <p className='text text-primary-emphasis'>
            IHT (Inheritance Tax) needs to be settled before the Grant of
            Probate is issued, a legal{' '}
            <span className=' text-warning'>document</span> that gives the
            executor the authority to manage the estate according to the will's
            terms.
          </p>
          <p className='text text-primary-emphasis'>
            Once you have a Grant of Probate, you can access the estate's assets
            to start paying off any debts or taxes - and this includes selling
            properties or investments if needed.
          </p>
          <p className='text text-primary-emphasis'>
            You can only start paying money out to beneficiaries once you've
            cleared all debts and taxes for the estate.
          </p>
          <p className='text text-primary-emphasis'>
            Sometimes the probate process is not straightforward, and you might
            face challenges like disputes over the will's validity or claims
            against the estate.
          </p>
          <CustomAlert backgroundColor='success'>
            <p className='text'>
              Probate loans can provide vital financial support to help you if
              you need it.
            </p>
          </CustomAlert>
          <p className='text text-primary-emphasis'>
            If you’re not available or willing to perform your executor duties,
            the court usually appoints an administrator or a Personal
            Representative.
          </p>
          <p className='text text-primary-emphasis'>
            They have the same role, but they must apply for a Grant of Letters
            of Administration instead of a Grant of Probate.
          </p>
          {/* <p className='text text-primary-emphasis'>
            Need help securing your probate advancement? Book a free,
            no-obligation initial call below, and we can walk you through the
            process.
          </p>
          <button
            onClick={() => {
              console.log('Not implemented');
            }}
            className='btn btn-success border-success-subtle  w-50'
          >
            Book Consultation
          </button> */}
        </div>
      </CardBody>
    </Card>
  );
};

export default DutiesAndResponsibilities;
