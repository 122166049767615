import { Container, Row, Col } from 'reactstrap';

const FooterPage = () => {
  return (
    <footer className='bg-white text-dark py-4 border-top'>
      <Container>
        <Row>
          <Col className='text-center text-md-left '>
            <p className='mb-0'>
              &copy; {new Date().getFullYear()} ALI. All rights reserved.
            </p>
          </Col>
          {/* <Col md={6} className='text-center text-md-right'>
            <p className='mb-0'>Designed and Developed by Your Company</p>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default FooterPage;
