import React, { useEffect, useState } from 'react';
import { submitPeopleAndEstateDataBaseUrl } from '../../../baseUrls';
import { getData } from '../../Functions/axiosFunctions';
import FormDataDisplay from './AllApplicationParts/FormDataDisplay';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Button,
  Row,
} from 'reactstrap';
import Progress from './AllApplicationParts/Progress';

const AllApplications = () => {
  const [allData, setAllData] = useState([]);
  const [visibleCard, setVisibleCard] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const uri = submitPeopleAndEstateDataBaseUrl();
        const solicitorId = '4B32B707-697A-4F45-8F9F-F8467CE36031';
        const response = await getData(uri, solicitorId);
        console.log(response);
        const sortedData = response.data.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
        );
        setAllData(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSave = (updatedData) => {
    setAllData((prevData) =>
      prevData.map((data) => (data.id === updatedData.id ? updatedData : data))
    );
    // Optionally, send updatedData to the server here
  };

  const toggleCardBody = (id) => {
    setVisibleCard(visibleCard === id ? null : id);
  };

  return (
    <div>
      <h2>All Applications</h2>
      {allData.map((data) => (
        <Card key={data.id} className='my-2'>
          <CardHeader className='text-center text-white d-flex justify-content-between align-items-center'>
            <Row>
              <h4>{data.dateCreated.split('T')[0]}</h4>
            </Row>

            <h3>
              {data.beneficiaries[0].title} {data.beneficiaries[0].firstName}{' '}
              {data.beneficiaries[0].lastName}
            </h3>

            <Button color='primary' onClick={() => toggleCardBody(data.id)}>
              {visibleCard === data.id ? 'Hide Details' : 'Show Details'}
            </Button>
          </CardHeader>
          {visibleCard === data.id && (
            <CardBody>
              <FormDataDisplay data={data} onSave={handleSave} />
            </CardBody>
          )}
          <CardFooter>
            <Progress data={data} />
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default AllApplications;
