import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Login = ({ handleCloseModal }) => {
  const [isRegistered, setIsRegistered] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  // State for login form
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  // State for registration form
  const [registerData, setRegisterData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    solicitorAgency: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    county: '',
    eircode: '',
    phoneNumber: '',
  });

  const navigate = useNavigate();

  const toggleForm = () => {
    setIsRegistered(!isRegistered);
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegisterChange = (e) => {
    const { name, value } = e.target;
    setRegisterData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLoginSubmit = (e) => {
    setErrorMessage('');
    e.preventDefault();
    // Handle login logic here
    console.log('Login data:');
    console.log(loginData);
    if (
      loginData.email === 'solicitor@example.com' &&
      loginData.password === 'password'
    ) {
      navigate('solicitorPage/AllApplications');
      handleCloseModal();
    } else {
      console.log('Invalid email or password. Please try again.');
      setErrorMessage('Invalid email or password. Please try again.');
    }
  };

  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    // Handle registration logic here
    console.log('Registration data:');
    console.log(registerData);
  };

  return (
    <div className='d-flex justify-content-center align-items-center  my-2'>
      <div
        className='p-4 border rounded'
        style={{ width: '100%', maxWidth: '400px' }}
      >
        <h5 className='mb-4'>{isRegistered ? 'Login' : 'Register'}</h5>
        {isRegistered ? (
          <Form onSubmit={handleLoginSubmit}>
            <FormGroup>
              <Label for='loginEmail'>Email</Label>
              <Input
                type='email'
                name='email'
                id='loginEmail'
                placeholder='Enter your email'
                value={loginData.email}
                onChange={handleLoginChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='loginPassword'>Password</Label>
              <Input
                type='password'
                name='password'
                id='loginPassword'
                placeholder='Enter your password'
                value={loginData.password}
                onChange={handleLoginChange}
                required
              />
            </FormGroup>
            <Button color='primary' type='submit' className='w-100 mb-2'>
              Login
            </Button>
            <Button color='link' onClick={toggleForm} className='w-100'>
              <p style={{ color: 'blue' }}> Not registered? Register here</p>
            </Button>
          </Form>
        ) : (
          <Form onSubmit={handleRegisterSubmit}>
            <FormGroup>
              <Label for='registerEmail'>Email</Label>
              <Input
                type='email'
                name='email'
                id='registerEmail'
                placeholder='Enter your email'
                value={registerData.email}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='registerPassword'>Password</Label>
              <Input
                type='password'
                name='password'
                id='registerPassword'
                placeholder='Enter your password'
                value={registerData.password}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='confirmPassword'>Confirm Password</Label>
              <Input
                type='password'
                name='confirmPassword'
                id='confirmPassword'
                placeholder='Confirm your password'
                value={registerData.confirmPassword}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='solicitorAgency'>Solicitor Agency</Label>
              <Input
                type='text'
                name='solicitorAgency'
                id='solicitorAgency'
                placeholder='Enter your solicitor agency'
                value={registerData.solicitorAgency}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='addressLine1'>Address Line 1</Label>
              <Input
                type='text'
                name='addressLine1'
                id='addressLine1'
                placeholder='Enter your address'
                value={registerData.addressLine1}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='addressLine2'>Address Line 2</Label>
              <Input
                type='text'
                name='addressLine2'
                id='addressLine2'
                placeholder='Enter your address'
                value={registerData.addressLine2}
                onChange={handleRegisterChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for='city'>City</Label>
              <Input
                type='text'
                name='city'
                id='city'
                placeholder='Enter your city'
                value={registerData.city}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='county'>County</Label>
              <Input
                type='text'
                name='county'
                id='county'
                placeholder='Enter your county'
                value={registerData.county}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='eircode'>Eircode</Label>
              <Input
                type='text'
                name='eircode'
                id='eircode'
                placeholder='Enter your eircode'
                value={registerData.eircode}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for='phoneNumber'>Phone Number</Label>
              <Input
                type='tel'
                name='phoneNumber'
                id='phoneNumber'
                placeholder='Enter your phone number'
                value={registerData.phoneNumber}
                onChange={handleRegisterChange}
                required
              />
            </FormGroup>
            <Button color='primary' type='submit' className='w-100 mb-2'>
              Register
            </Button>
            <Button color='link' onClick={toggleForm} className='w-100'>
              <p style={{ color: 'blue' }}>Already registered? Login here</p>
            </Button>
          </Form>
        )}
        {errorMessage !== '' ? (
          <div className='alert alert-danger' role='alert'>
            {errorMessage}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Login;
