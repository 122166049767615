import React, { useState } from 'react';
import CustomModal from '../Generic/CustomModal';

import Login from './Login';

const FloatingButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = () => {
    setModalOpen(true);
    //window.open('https://form.jotform.com/241422880512046', '_blank');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <button
        className='floating-button bnt btn-lg btn-success rounded-pill py-3 px-5'
        color='primary'
        onClick={handleClick}
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: '1000',
        }}
      >
        Login
      </button>
      <CustomModal isOpen={modalOpen} toggle={handleCloseModal} title=''>
        {/* You can pass any content as children to the Modal component */}
        <div className='modal-content bg-light'>
          <Login handleCloseModal={handleCloseModal} />
        </div>
      </CustomModal>
    </>
  );
};

export default FloatingButton;
