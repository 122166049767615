export const getAuthAliProbateApiBaseUrl = () => {
  // Construct the base URL for the API
  let baseUrl = window.location.origin;

  // For development, use the specified API port and append /api/Auth
  if (process.env.NODE_ENV === 'development') {
    const apiPort = process.env.REACT_APP_API_PORT;
    const defaultPortIndex = baseUrl.lastIndexOf(':');
    if (defaultPortIndex !== -1) {
      baseUrl = baseUrl.substring(0, defaultPortIndex);
    }
    baseUrl += `:${apiPort}`;
  }

  baseUrl += '/api/Auth'; // Append /api/Auth regardless of environment

  return baseUrl;
};

export const postHomeAliProbateApiBaseUrl = () => {
  // Construct the base URL for the API
  let baseUrl = window.location.origin;

  // For development, use the specified API port and append /api/Auth
  if (process.env.NODE_ENV === 'development') {
    const apiPort = process.env.REACT_APP_API_PORT;
    const defaultPortIndex = baseUrl.lastIndexOf(':');
    if (defaultPortIndex !== -1) {
      baseUrl = baseUrl.substring(0, defaultPortIndex);
    }
    baseUrl += `:${apiPort}`;
  }

  baseUrl += '/api/Home'; // Append /api/Auth regardless of environment

  return baseUrl;
};

export const submitPeopleAndEstateDataBaseUrl = () => {
  // Construct the base URL for the API
  let baseUrl = window.location.origin;

  // For development, use the specified API port and append /api/Auth
  if (process.env.NODE_ENV === 'development') {
    const apiPort = process.env.REACT_APP_API_PORT;
    const defaultPortIndex = baseUrl.lastIndexOf(':');
    if (defaultPortIndex !== -1) {
      baseUrl = baseUrl.substring(0, defaultPortIndex);
    }
    baseUrl += `:${apiPort}`;
  }

  baseUrl += '/api/Home'; // Append  regardless of environment

  return baseUrl;
};

export const uploadFilesBaseUrl = () => {
  // Construct the base URL for the API
  let baseUrl = window.location.origin;

  // For development, use the specified API port and append /api/Auth
  if (process.env.NODE_ENV === 'development') {
    const apiPort = process.env.REACT_APP_API_PORT;
    const defaultPortIndex = baseUrl.lastIndexOf(':');
    if (defaultPortIndex !== -1) {
      baseUrl = baseUrl.substring(0, defaultPortIndex);
    }
    baseUrl += `:${apiPort}`;
  }

  baseUrl += '/api/Home/uploadFiles'; // Append  regardless of environment

  return baseUrl;
};
