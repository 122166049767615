import React, { useState } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Container,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { validatePPS } from '../../../Functions/helpers';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../../Functions/axiosFunctions';
import { submitPeopleAndEstateDataBaseUrl } from '../../../../baseUrls';

const PeopleAndEstatePart = () => {
  const navigate = useNavigate();

  const [deceasedInfo, setDeceasedInfo] = useState({
    firstName: '',
    lastName: '',
  });

  const [beneficiaries, setBeneficiaries] = useState([
    { title: '', firstName: '', lastName: '', pps: '' },
  ]);

  const [estates, setEstates] = useState([{ description: '', value: '' }]);

  const [dispute, setDispute] = useState('');
  const [errors, setErrors] = useState({});

  const handleDeceasedChange = (e) => {
    const { name, value } = e.target;
    setDeceasedInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBeneficiaryChange = (index, e) => {
    const { name, value } = e.target;
    const newBeneficiaries = [...beneficiaries];
    newBeneficiaries[index][name] = value;
    setBeneficiaries(newBeneficiaries);
  };

  const addBeneficiary = () => {
    setBeneficiaries([
      ...beneficiaries,
      { title: '', firstName: '', lastName: '', pps: '' },
    ]);
  };

  const handleEstateChange = (index, e) => {
    const { name, value } = e.target;
    const newEstates = [...estates];
    newEstates[index][name] = value;
    setEstates(newEstates);
  };

  const addEstate = () => {
    setEstates([...estates, { description: '', value: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate PPS numbers
    const ppsErrors = beneficiaries
      .map((beneficiary, index) => {
        return validatePPS(beneficiary.pps)
          ? null
          : `Invalid PPS number at beneficiary ${index + 1}`;
      })
      .filter((error) => error !== null);

    if (ppsErrors.length > 0) {
      setErrors({ pps: ppsErrors });
      return;
    }

    // Combine all data into a single object
    const formData = {
      deceasedInfo,
      beneficiaries,
      estates,
      dispute,
      solicitorId: '4B32B707-697A-4F45-8F9F-F8467CE36031',
    };
    console.log('Form Data:', formData);
    const url = submitPeopleAndEstateDataBaseUrl();
    const res = await postData(formData, url);
    console.log(res);
    navigate('/solicitorPage/AllApplications');

    // Clear errors
    setErrors({});
  };

  const isLastBeneficiaryFilled = () => {
    const lastBeneficiary = beneficiaries[beneficiaries.length - 1];
    return (
      lastBeneficiary.title &&
      lastBeneficiary.firstName &&
      lastBeneficiary.lastName &&
      lastBeneficiary.pps
    );
  };

  const isLastEstateFilled = () => {
    const lastEstate = estates[estates.length - 1];
    return lastEstate.description && lastEstate.value;
  };

  return (
    <Container>
      <h2 className='mt-4'>Deceased Information</h2>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for='deceasedFirstName'>First Name</Label>
              <Input
                type='text'
                name='firstName'
                id='deceasedFirstName'
                placeholder='Enter first name'
                value={deceasedInfo.firstName}
                onChange={handleDeceasedChange}
                required
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for='deceasedLastName'>Last Name</Label>
              <Input
                type='text'
                name='lastName'
                id='deceasedLastName'
                placeholder='Enter last name'
                value={deceasedInfo.lastName}
                onChange={handleDeceasedChange}
                required
              />
            </FormGroup>
          </Col>
        </Row>

        <h2 className='mt-4'>Beneficiaries Information</h2>
        {beneficiaries.map((beneficiary, index) => (
          <div key={index} className='mb-4'>
            <Row>
              <Col md={2}>
                <FormGroup>
                  <Label for={`beneficiaryTitle${index}`}>Title</Label>
                  <Input
                    type='text'
                    name='title'
                    id={`beneficiaryTitle${index}`}
                    placeholder='Enter title'
                    value={beneficiary.title}
                    onChange={(e) => handleBeneficiaryChange(index, e)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for={`beneficiaryFirstName${index}`}>First Name</Label>
                  <Input
                    type='text'
                    name='firstName'
                    id={`beneficiaryFirstName${index}`}
                    placeholder='Enter first name'
                    value={beneficiary.firstName}
                    onChange={(e) => handleBeneficiaryChange(index, e)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={5}>
                <FormGroup>
                  <Label for={`beneficiaryLastName${index}`}>Last Name</Label>
                  <Input
                    type='text'
                    name='lastName'
                    id={`beneficiaryLastName${index}`}
                    placeholder='Enter last name'
                    value={beneficiary.lastName}
                    onChange={(e) => handleBeneficiaryChange(index, e)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={5} className='mx-md-auto'>
                <FormGroup>
                  <Label for={`beneficiaryPPS${index}`}>PPS Number</Label>
                  <Input
                    type='text'
                    name='pps'
                    id={`beneficiaryPPS${index}`}
                    placeholder='Enter PPS number'
                    value={beneficiary.pps}
                    onChange={(e) => handleBeneficiaryChange(index, e)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        ))}
        {errors.pps &&
          errors.pps.map((error, idx) => (
            <Alert key={idx} className=' bg-danger'>
              {error}
            </Alert>
          ))}
        <Button
          color='primary'
          onClick={addBeneficiary}
          className='mb-4'
          disabled={!isLastBeneficiaryFilled()}
        >
          <FaPlusCircle /> Add Beneficiary
        </Button>

        <h2 className='mt-4'>Estate Information</h2>
        {estates.map((estate, index) => (
          <div key={index} className='mb-4'>
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label for={`estateDescription${index}`}>Description</Label>
                  <Input
                    type='text'
                    name='description'
                    id={`estateDescription${index}`}
                    placeholder='Enter description'
                    value={estate.description}
                    onChange={(e) => handleEstateChange(index, e)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for={`estateValue${index}`}>Value</Label>
                  <Input
                    type='number'
                    name='value'
                    id={`estateValue${index}`}
                    placeholder='Enter value'
                    value={estate.value}
                    onChange={(e) => handleEstateChange(index, e)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        ))}
        <Button
          color='primary'
          onClick={addEstate}
          className='mb-4'
          disabled={!isLastEstateFilled()}
        >
          <FaPlusCircle /> Add Estate
        </Button>

        <h2 className='mt-4'>Dispute Information</h2>
        <FormGroup>
          <Label for='dispute'>Dispute</Label>
          <Input
            type='textarea'
            name='dispute'
            id='dispute'
            placeholder='Enter dispute details'
            value={dispute}
            onChange={(e) => setDispute(e.target.value)}
          />
        </FormGroup>

        <Button color='success' type='submit'>
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default PeopleAndEstatePart;
