import axios from 'axios';

export const postData = async (dataObj, apiUrl) => {
  try {
    const response = await axios.post(apiUrl, dataObj, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response;
  } catch (error) {
    console.error('Error fetching form:', error);
  }
};
export const getData = async (apiUrl, solicitorId) => {
  try {
    const response = await axios.get(`${apiUrl}/${solicitorId}`);

    return response;
  } catch (error) {
    console.error('Error fetching form:', error);
  }
};

export const uploadFiles = async (apiUrl, allFilesArr, setUploadStatus) => {
  console.log(apiUrl);
  console.log(allFilesArr);
  const formData = new FormData();
  allFilesArr.forEach((file) => {
    formData.append('files', file);
  });
  try {
    const response = await axios.post(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    setUploadStatus('Files uploaded successfully!');
    console.log(response);
    return response;
  } catch (error) {
    console.error('Error uploading files:', error);
    setUploadStatus('Error uploading files');
  }
};
