import { useEffect, useState } from 'react';
import { Button, Collapse, NavLink, Navbar, NavbarToggler } from 'reactstrap';
import { motion } from 'framer-motion';
import NavButton from './Generic/NavButton';
import { Link, useNavigate } from 'react-router-dom';

export const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isImageHovered, setIsImageHovered] = useState(false);

  const navigate = useNavigate();

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 576);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const listVariants = {
    initial: { y: '-10rem' },
    animate: {
      y: 0,
      transition: {
        ease: 'easeInOut',
        duration: 1,
        staggerChildren: 0.3,
      },
    },
  };

  const logoVariant = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        delay: 1,
        duration: 1,
      },
    },
  };

  const imageVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.4 }, delay: 0.5 },
    whileHover: {
      filter: 'grayscale(100%)', // Apply 100% grayscale to make the image black and white
      transition: {
        duration: 1, // Duration of the transition to grayscale
      },
    },
  };

  return (
    <>
      <header>
        <div>
          <Navbar className='py-2 navbar-expand-sm navbar-toggleable-sm border-0'>
            <div className='container'>
              <div className='row justify-content-center align-items-center'>
                <div className='col text-center'>
                  <motion.img
                    id='logo'
                    src='/img/ALI logo.png'
                    alt='ALI logo'
                    variants={logoVariant}
                    initial='initial'
                    animate='animate'
                    whileHover={{
                      filter: 'invert(50%)',
                      cursor: 'pointer',
                      opacity: 0.5,
                    }}
                    onClick={() => {
                      navigate('');
                    }}
                    style={{
                      filter: 'invert(100%)',
                    }}
                  />
                </div>
                {isMobile && (
                  <div className='col text-center'>
                    <NavbarToggler
                      color='white'
                      onClick={toggleNavbar}
                      className='mr-2 border-black bg-black'
                    />
                  </div>
                )}
              </div>
              <div className='row my-lg-2 justify-content-center'>
                <div className='col text-center'>
                  <Collapse
                    className='d-sm-inline-flex flex-sm-row-reverse'
                    isOpen={collapsed}
                    navbar
                  >
                    <motion.ul
                      variants={listVariants}
                      initial='initial'
                      animate='animate'
                      className='navbar-nav mx-auto d-flex flex-wrap justify-content-evenly align-items-center'
                    >
                      <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl=''
                        >
                          Home
                        </NavButton>
                      </motion.li>
                      {/* <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl='executorDutiesAndResponribilities'
                        >
                          Executor Duties & Responsibilities
                        </NavButton>
                      </motion.li> */}
                      <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl='understanding'
                        >
                          Understanding Probate
                        </NavButton>
                      </motion.li>
                      {/* <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl='products'
                        >
                          Our Loan Products
                        </NavButton>
                      </motion.li> */}
                      <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl='howItWorks'
                        >
                          Understanding Probate Advancement Process
                        </NavButton>
                      </motion.li>
                      <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl='benefits'
                        >
                          Benefits
                        </NavButton>
                      </motion.li>
                      {/* <motion.li
                        className='nav-item mx-3 text-center'
                        variants={listVariants}
                        whileHover={{
                          scale: 0.98,
                          transition: {
                            duration: 0.3,
                          },
                        }}
                        style={{
                          height: '100%',
                          width: isMobile ? '100%' : '40%',
                        }}
                      >
                        <NavButton
                          isMobile={isMobile}
                          toggleNavbar={toggleNavbar}
                          navUrl='faq'
                        >
                          FAQ
                        </NavButton>
                      </motion.li> */}
                    </motion.ul>
                  </Collapse>
                </div>
              </div>
            </div>
          </Navbar>
        </div>
      </header>

      <motion.div
        style={{
          height: '300px',
          width: '100%',
          position: 'relative',
          marginBottom: '-5px',
        }}
        onMouseEnter={() => {
          setIsImageHovered(true);
        }}
        onMouseLeave={() => {
          setIsImageHovered(false);
        }}
      >
        <motion.img
          src='/img/handshake.jpg'
          alt='handshake'
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
            filter: isImageHovered ? 'grayscale(100%)' : 'none',
            transition: 'filter 0.3s ease',
          }}
          initial='hidden'
          animate='visible'
          variants={imageVariants}
        />
        <motion.div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            height: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Great Vibes, cursive',
            cursor: 'pointer',
          }}
          initial='hidden'
          animate='visible'
          onClick={() => {
            navigate('');
          }}
          className=' row'
        >
          <motion.p
            id='probate-loans-text'
            style={{
              width: 'auto',
              textAlign: 'center',
              fontSize: '10vw',
              whiteSpace: 'nowrap',
              color: isImageHovered
                ? 'rgba(77, 3, 4, 1)'
                : 'rgba(255, 255, 255, 1)',
              transition: 'color 1s ease', // Corrected from 'filter' to 'color'
            }}
            className=' my-auto mx-auto'
          >
            Probate Advancements
          </motion.p>
        </motion.div>
      </motion.div>
    </>
  );
};
