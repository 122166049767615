import { Card, CardHeader } from 'reactstrap';
import PeopleAndEstatePart from './NewApplicationParts/PeopleAndEstatePart';

const NewApplication = () => {
  return (
    <Card className=' bg-light text-black-50 mt-3'>
      <CardHeader className='title text-white roboto-thin-italic'>
        New Application
      </CardHeader>
      <PeopleAndEstatePart />
    </Card>
  );
};

export default NewApplication;
