import React from 'react';

const BPLoans = () => {
  return (
    <div className='roboto-medium  text-primary-emphasis text  text-start mt-5'>
      <p>
        The other popular advancement to pay inheritance tax is a bridging
        advancement.{' '}
      </p>
      <br />
      <p>
        They work in the same way as a standard probate advancement, but it’s
        secured against your property instead of the estate.
      </p>
      <br />
      <p>
        This means you take on more risk as a borrower, because your property is
        at stake in the agreement. But you can qualify for lower interest rates
        because your lender also has less risk.
      </p>
      <br />
      <p>
        We have a{' '}
        <span className='text-warning roboto-regular-italic'>
          full guide on the process of using a bridging loan to pay for IHT.
        </span>
      </p>
      <br />
      <p>
        And if you’re unsure about the risks or which option is best for you, we
        recommend speaking to one of our qualified advisers.
      </p>
      <br />
      <p>
        We can have a look at your wider financial situation and walk you
        through the pros and cons of each option, so you feel comfortable you’re
        making the right call for you.
      </p>
    </div>
  );
};

export default BPLoans;
