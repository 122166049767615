import React from 'react';
import SolicitorNav from './SolicitorNav';
import { useLocation } from 'react-router-dom';
import AllApplications from './AllApplications';
import NewApplication from './NewApplication';

const BaseSolicitorPage = () => {
  const location = useLocation();
  const pathname = location.pathname;
  return (
    <>
      <SolicitorNav />
      {pathname === '/solicitorPage/AllApplications' && <AllApplications />}
      {pathname === '/solicitorPage/NewApplication' && <NewApplication />}
    </>
  );
};

export default BaseSolicitorPage;
