import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { uploadFiles } from '../../../Functions/axiosFunctions';
import { uploadFilesBaseUrl } from '../../../../baseUrls';

const FilesDropZone = ({ uploadedFiles, setUploadedFiles, applicationId }) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [isError, setIsError] = useState(false);

  const validExtensions = [
    '.jpeg',
    '.jpg',
    '.png',
    '.gif',
    '.bmp',
    '.webp',
    '.pdf',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.txt',
    '.rtf',
    '.odt',
    '.ods',
    '.odp',
  ];

  const uploadFilesHandler = async () => {
    setUploadStatus('Uploading');
    console.log('Uploading files: ');
    console.log(uploadedFiles);
    const uri = uploadFilesBaseUrl();
    const fullUri = `${uri}/${applicationId}`;
    try {
      const response = await uploadFiles(
        fullUri,
        uploadedFiles,
        setUploadStatus
      );
      console.log(response);

      if (response && response.status === 200) {
        setIsError(false);
        setUploadedFiles([]);
        setAcceptedFiles([]);
        setFileRejections([]);
        setUploadStatus('Files uploaded sucessfully');
      }
    } catch (error) {
      setIsError(true);
      console.error('Error uploading files:', error);
      setUploadStatus('Error uploading files');
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const newAcceptedFiles = [];
      const newFileRejections = [];

      acceptedFiles.forEach((file) => {
        const fileExtension = file.name
          .slice(file.name.lastIndexOf('.'))
          .toLowerCase();
        if (validExtensions.includes(fileExtension)) {
          newAcceptedFiles.push(file);
        } else {
          newFileRejections.push({
            file,
            errors: [
              { code: 'file-invalid-type', message: 'File type not accepted' },
            ],
          });
        }
      });

      setUploadedFiles((prevFiles) => [...prevFiles, ...newAcceptedFiles]);
      setAcceptedFiles((prev) => [...prev, ...newAcceptedFiles]);
      setFileRejections((prev) => [...prev, ...newFileRejections]);
    },
    [setUploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
  });

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={`${file.path}-${file.lastModified}`}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <li key={`${file.path}-${file.lastModified}`}>
      {file.path} - {file.size} bytes
      <ul>
        {errors.map((e) => (
          <li key={e.code}>{e.message}</li>
        ))}
      </ul>
    </li>
  ));

  return (
    <CardBody>
      <div
        style={{
          border: '1px solid white',
          borderRadius: '5px',
          padding: '5px',
        }}
        className='shadow-lg'
      >
        <CardHeader>
          <CardTitle className='title'>Upload Supporting Documents</CardTitle>
        </CardHeader>

        <div className='my-4 bg-dark p-2 rounded-2'>
          <CardBody>
            <div
              {...getRootProps({ className: 'dropzone' })}
              style={{
                border: '2px dashed #007bff',
                padding: '20px',
                textAlign: 'center',
              }}
            >
              <input {...getInputProps()} />
              <p>Drag & drop some files here, or click to select files</p>
              <em>
                (Accepted file types: jpeg, jpg, png, gif, bmp, webp, pdf, doc,
                docx, xls, xlsx, ppt, pptx, txt, rtf, odt, ods, odp)
              </em>
            </div>
            <aside className='mt-3'>
              <h4>Accepted files</h4>
              <ul>{acceptedFileItems}</ul>
              <h4>Rejected files</h4>
              <ul>{fileRejectionItems}</ul>
            </aside>
            <Row>
              <Button onClick={uploadFilesHandler} className='btn-info ms-auto'>
                Upload files
              </Button>
            </Row>
            {uploadStatus !== '' ? (
              <Row>
                <p
                  className={`text-center my-1 ${
                    isError ? 'text-danger' : 'text-success'
                  }`}
                >
                  {uploadStatus}
                </p>
              </Row>
            ) : (
              <></>
            )}
          </CardBody>
        </div>
      </div>
    </CardBody>
  );
};

export default FilesDropZone;
