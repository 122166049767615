import React from 'react';
import CustomAlert from '../../Generic/CustomAlert';

const PLoansFB = () => {
  return (
    <div className='text mt-5 roboto-medium  text-primary-emphasis'>
      <p>
        Probate advancements for beneficiaries, also known as an inheritance
        advance, are designed for beneficiaries who want to access their
        inheritance sooner rather than later.
      </p>
      <br />
      <p>
        The primary benefit is the ability it provides to gain access to the
        inheritance even before the probate process concludes (which can easily
        take 18 months in Ireland).
      </p>
      <br />
      <p>
        Whether you need to pay off personal debts with high interest rates,
        cover immediate costs to do with the estate, or simply pay for something
        you’ve had your eye on for a while - like deposit for a new house - an
        inheritance advance can fast-track the process.
      </p>
      <br />
      <div className=' text-center mx-lg-5'>
        <h4>And one of the key advantages of the Inheritance Advance?</h4>
        <CustomAlert backgroundColor='info'>
          It doesn't require any personal liability, or monthly repayments.
        </CustomAlert>
      </div>

      <br />
      <p>
        This means your income level doesn't impact your eligibility for a
        advancement.
      </p>
      <br />
      <p>
        And it also means you're not personally liable for the repayment of the
        advancement, because it's made directly from the estate once the probate
        concludes.
      </p>
      <br />
      <p>
        {' '}
        This means it's a relatively secure and straightforward financial
        solution compared to many other types of advancements.
      </p>
    </div>
  );
};

export default PLoansFB;
