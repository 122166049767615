import { data } from 'jquery';
import React from 'react';
import { CiNoWaitingSign } from 'react-icons/ci';
import { IoCheckmarkDoneCircleOutline } from 'react-icons/io5';

const Progress = ({ data }) => {
  const iconSize = 30;

  const renderDivider = (
    height = '1px',
    color = 'white',
    className = 'col-1',
    style = {}
  ) => {
    return (
      <div
        className={`mx-1 mb-3 ${className}`}
        style={{ height, backgroundColor: color, ...style }}
      ></div>
    );
  };

  return (
    <div className='d-flex justify-content-center align-items-center'>
      <div className='d-flex flex-column align-items-center'>
        <IoCheckmarkDoneCircleOutline size={iconSize} color='green' />
        <small className='mt-1' style={{ fontSize: '10px' }}>
          Applied
        </small>
      </div>
      {renderDivider()}
      <div className='d-flex flex-column align-items-center'>
        {data.filesUploaded === false ? (
          <CiNoWaitingSign size={iconSize} color='gray' />
        ) : (
          <IoCheckmarkDoneCircleOutline size={iconSize} color='green' />
        )}

        <small className='mt-1' style={{ fontSize: '10px' }}>
          Files
        </small>
      </div>
      {renderDivider()}
      <div className='d-flex flex-column align-items-center'>
        {data.undertakingReady === false ? (
          <CiNoWaitingSign size={iconSize} color='gray' />
        ) : (
          <IoCheckmarkDoneCircleOutline size={iconSize} color='green' />
        )}
        <small className='mt-1' style={{ fontSize: '10px' }}>
          Undertaking
        </small>
      </div>
      {renderDivider()}
      <div className='d-flex flex-column align-items-center'>
        {data.agreementReady === false ? (
          <CiNoWaitingSign size={iconSize} color='gray' />
        ) : (
          <IoCheckmarkDoneCircleOutline size={iconSize} color='green' />
        )}
        <small className='mt-1' style={{ fontSize: '10px' }}>
          Agreement
        </small>
      </div>
    </div>
  );
};

export default Progress;
