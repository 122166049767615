import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
} from 'reactstrap';
import CustomCard from '../Generic/CustomCard';

import {
  TbPentagonNumber1,
  TbPentagonNumber2,
  TbPentagonNumber3,
} from 'react-icons/tb';

const Benefits = () => {
  return (
    <Card className='whatIsProbate bg-white border-0'>
      <CardHeader className=' bg-light border-0 text-center'>
        <CardTitle className='title text-black roboto-medium'>
          The Benefits of Probate Advancements
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className=' d-flex flex-column align-items-center justify-content-evenly'>
          <TbPentagonNumber1
            color='#62C462'
            size={100}
            style={{ filter: 'drop-shadow(3px 3px 4px rgb(0, 0, 0))' }}
          />
          <CardSubtitle className='title text-primary-emphasis'>
            No Proof of Income Required
          </CardSubtitle>
          <CardText className='text text-primary-emphasis'>
            Most loans are based on your financial history and income, which
            isn’t always favourable for everyone. <br />
            <br />
            But as you're taking an advance against the estate there is no need
            to look at your income to gauge if you'd be able to afford
            repayments.
          </CardText>
        </div>
        <hr />
        <div className=' d-flex flex-column align-items-center justify-content-evenly'>
          <TbPentagonNumber2
            color='#62C462'
            size={100}
            style={{ filter: 'drop-shadow(3px 3px 4px rgb(0, 0, 0))' }}
          />
          <CardSubtitle className='title text-primary-emphasis'>
            No Monthly Repayments
          </CardSubtitle>
          <CardText className='text text-primary-emphasis'>
            Traditional loans also typically require regular payments to repay
            the borrowed sum and interest, which can be a strain on your
            finances. <br />
            <br /> With probate advancements, there are no monthly repayments.
            It's all repaid directly from the estate once the probate process
            concludes.
          </CardText>
        </div>
        <hr />
        <div className=' d-flex flex-column align-items-center justify-content-evenly'>
          <TbPentagonNumber3
            color='#62C462'
            size={100}
            style={{ filter: 'drop-shadow(3px 3px 4px rgb(0, 0, 0))' }}
          />
          <CardSubtitle className='title text-primary-emphasis'>
            Repayment Made Directly from the Estate
          </CardSubtitle>
          <div className='text text-primary-emphasis'>
            <strong>This method of repayment provides two key benefits:</strong>{' '}
            <div className='row d-flex align-items-center justify-content-evenly'>
              <div className='col-lg-5  my-2'>
                <CustomCard
                  backgroundColor='success'
                  shape='rectangle'
                  width='100%'
                  height='100%'
                >
                  It eliminates personal liability for the advance, meaning if
                  the estate's value falls short you aren't personally
                  responsible for repaying the difference.
                </CustomCard>
              </div>
              <div className='col-lg-5  my-2'>
                <CustomCard
                  backgroundColor='success'
                  shape='rectangle'
                  width='100%'
                  height='100%'
                >
                  It ensures the advance doesn't impact your personal finance or
                  credit score in any way - it's linked solely to the estate.
                </CustomCard>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Benefits;
