import React from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import CustomCard from '../Generic/CustomCard';
import { MdOutlineRealEstateAgent } from 'react-icons/md';
import { RiMoneyEuroCircleLine } from 'react-icons/ri';
import { GiTakeMyMoney } from 'react-icons/gi';
import CustomAlert from '../Generic/CustomAlert';

const HowItWorks = () => {
  return (
    <Card className=' bg-white border-0 text-center'>
      <CardHeader className='bg-light border-0'>
        <CardTitle className='title roboto-medium text-black'>
          Probate Advancement Process
        </CardTitle>
      </CardHeader>
      <CardBody className='text border-0 '>
        <div className='text-center mx-lg-5'>
          <CustomAlert backgroundColor='info'>
            <strong>
              Probate advancements are slightly different to traditional loans.
            </strong>
            <br />
            While conventional loans are often secured against an existing
            property and require credit checks, income proof, and monthly
            repayments, probate advancements work uniquely within the probate
            process.
          </CustomAlert>
        </div>
        <ListGroup>
          <ListGroupItem className=' bg-white border-0'>
            <div className='ms-2 '>
              <CardTitle className='mt-4 title roboto-medium text-primary-emphasis'>
                Estate Valuation
              </CardTitle>
              <MdOutlineRealEstateAgent
                color='#17191B'
                size={80}
                className='icon'
              />
              <div className='mt-3 roboto-medium text-primary-emphasis text-start'>
                <p>This is the first step in the process.</p>
                <br />
                <p>
                  A valuation is conducted that includes all the assets of the
                  deceased, including:
                </p>
                <div className=' d-flex align align-items-center justify-content-evenly flex-wrap'>
                  <CustomCard
                    backgroundColor='success'
                    shape='circle'
                    width={150}
                    height={150}
                  >
                    Property
                  </CustomCard>
                  <CustomCard
                    backgroundColor='success'
                    shape='circle'
                    width={150}
                    height={150}
                  >
                    Cash
                  </CustomCard>
                  <CustomCard
                    backgroundColor='success'
                    shape='circle'
                    width={150}
                    height={150}
                  >
                    Investments
                  </CustomCard>
                  <CustomCard
                    backgroundColor='success'
                    shape='circle'
                    width={150}
                    height={150}
                  >
                    Savings
                  </CustomCard>
                </div>
                <p className=' text-center'>
                  And any other possessions of value like jewellery and cars
                </p>
                <br />
                <p className=' text-center'>
                  The valuation of the estate is usually conducted by a
                  professional valuation expert to ensure an accurate and fair
                  market value of all assets in the estate.
                </p>
                <br />
                <p className=' text-center'>
                  These assets are listed and itemized in the Revenue Affidavit
                  (CA 24) which is submitted to Revenue as part of the probate
                  process.
                </p>
                <br />
                <p className=' text-center'>
                  This form CA 24 is uploaded by the applicant's solicitor to
                  our platform to determine the amount that can be advanced
                  against the value of the estate.
                </p>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem className='bg-white border-0 border-top '>
            <div className='ms-2 me-auto'>
              <div>
                <CardTitle className='mt-3 title roboto-medium text-primary-emphasis'>
                  Advancement Amounts: How much could we advance?
                </CardTitle>
                <RiMoneyEuroCircleLine
                  className='icon'
                  color='#17191B'
                  size={80}
                />
              </div>
              <div className='mt-3 text text-start roboto-medium text-primary-emphasis'>
                <p>
                  We can typically advance up to 60% of the value of your
                  inheritance.
                </p>
                <br />
                <p>
                  There is generally no maximum amount you can advance as long
                  as your inheritance will cover it within the range above.
                </p>
              </div>
            </div>
          </ListGroupItem>
          <ListGroupItem className=' bg-white border-0 border-top '>
            <div className='ms-2 me-auto'>
              <div>
                <CardTitle className='mt-3 title roboto-medium text-primary-emphasis'>
                  Repayment Process Explained
                </CardTitle>
                <GiTakeMyMoney className='icon' color='#17191B' size={80} />
              </div>
              <div className='mt-3 text text-start roboto-medium text-primary-emphasis'>
                <p>
                  Probate advancements are unique in that the lender assumes a
                  significant amount of risk, but it’s backed up by the estate
                  value.
                </p>
                <br />
                <p>
                  Once your advancement is issued, the repayment comes directly
                  from the estate, meaning your lender is dependent on the
                  successful conclusion.
                </p>
                <br />
                <p>
                  So when we are considering your advance, we will take into
                  account the types of assets: their market value, the potential
                  for depreciation, and the estimated duration of the probate
                  process among other things.
                </p>
                <br />
                <div className='text-center mx-lg-5'>
                  <CustomAlert backgroundColor='info'>
                    Also, probate advancements don’t require you to have a
                    certain credit score or a particular level of income, and
                    there are no monthly repayments.
                  </CustomAlert>
                </div>
              </div>
            </div>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default HowItWorks;
