import React from 'react';
import { Alert, CardBody, ListGroup, ListGroupItem } from 'reactstrap';
import CustomAlert from '../../Generic/CustomAlert';

const WhoAreProbateLoansFor = () => {
  return (
    <div className='whatIsProbate'>
      <CardBody>
        <div className='mt-5 roboto-medium  text-primary-emphasis'>
          <div className='text '>
            <div className=' roboto-regular text-center mx-lg-5'>
              <CustomAlert backgroundColor='info'>
                Probate advancements are for beneficiaries or executors of an
                estate.
              </CustomAlert>
            </div>
            <br />
            <p className=' text-lg-start me-lg-5'>
              As the probate process unfolds, beneficiaries and executors often
              find themselves facing considerable financial pressure.
              <br />
              <br />
              For instance:
            </p>
            <div className='roboto-tregular  text-center mx-lg-5'>
              <CustomAlert backgroundColor='white'>
                <ListGroupItem className='bg-white fancy-list-group-item text-primary border-light'>
                  There may be immediate funeral costs to cover
                </ListGroupItem>
                <hr />
                <ListGroupItem className='bg-white fancy-list-group-item text-primary border-light'>
                  Or ongoing expenses like property maintenance for the
                  deceased's home
                </ListGroupItem>
                <hr />
                <ListGroupItem className='bg-white fancy-list-group-item  text-primary border-light'>
                  Or an executor may need to settle outstanding debts
                </ListGroupItem>
                <hr />
                <ListGroupItem className='bg-white fancy-list-group-item text-primary border-light'>
                  Or there might be inheritance taxes tied to the estate.
                </ListGroupItem>
              </CustomAlert>
            </div>
            <br />
            <p className=' text-lg-start me-lg-5'>
              These expenses can be significant, and are{' '}
              <span className='text-warning roboto-regular-italic'>
                usually required to be paid before the inheritance is paid out
                to the beneficiaries.
              </span>
              <br />
              So, you might find yourself in a tight financial spot, needing
              funds that are effectively locked away in the probate process.
            </p>
            <br />
            <p className=' text-lg-end ms-lg-5'>
              This is where probate advancements can prove to be a lifeline.
            </p>
            <br />
            <div className=' text-center mx-lg-5'>
              <CustomAlert backgroundColor='warning'>
                By effectively providing access to the inheritance before the
                probate process concludes, these advancements can alleviate the
                financial strain and allow for necessary expenses to be covered.
              </CustomAlert>
            </div>
          </div>
        </div>
      </CardBody>
    </div>
  );
};

export default WhoAreProbateLoansFor;
