import HowItWorks from './components/Pages/HowItWorks';
import Benefits from './components/Pages/Benefits';
import Products from './components/Pages/Products';
import { FaqPage } from './components/Pages/FaqPage';
import DutiesAndResponsibilities from './components/Pages/DutiesAndResponsibilities';
import UnderstandingProbate from './components/Pages/UnderstandingProbate';
import BaseSolicitorPage from './components/Pages/SolicitorPage/BaseSolicitorPage';
import Home from './components/Pages/Home';

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: '/understanding',
    element: <UnderstandingProbate />,
  },
  {
    path: '/products',
    element: <Products />,
  },
  {
    path: '/howItWorks',
    element: <HowItWorks />,
  },
  {
    path: '/benefits',
    element: <Benefits />,
  },
  {
    path: '/faq',
    element: <FaqPage />,
  },
  {
    path: '/executorDutiesAndResponribilities',
    element: <DutiesAndResponsibilities />,
  },
  {
    path: '/solicitorPage/AllApplications',
    element: <BaseSolicitorPage />,
  },
  {
    path: '/solicitorPage/NewApplication',
    element: <BaseSolicitorPage />,
  },
];

export default AppRoutes;
